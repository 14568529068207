import Api from '../../api';
import ACTIONS from '../../actions';

class AmoCRMService {
  constructor() {
    this.store = this.getStore('AmoCRM');
  }

  get = () => this.store.getState();

  subscribe = (callback) => this.store.subscribe(callback);

  uploadContacts = async (file, tag) => {
    try {
      const payload = await Api.AmoCRM.uploadContacts(file, tag);
      this.store.dispatch({
        type: ACTIONS.AMOCRM.UPLOADCONTACTS,
        payload,
      });
    } catch (error) {
      this.store.dispatch({ type: ACTIONS.AMOCRM.UPLOADCONTACTSFAIL });
    }
  };

  processAmoReport = async (file) => {
    try {
      const blob = await Api.AmoCRM.processAmoReport(file);
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name.replace('.xlsx', '_обработанный.xlsx');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      this.store.dispatch({ type: ACTIONS.AMOCRM.UPLOADFILE });
    } catch (err) {
      err.req.xhr.response.text().then((err) => console.log(`occur error: ${err}`));
      this.store.dispatch({ type: ACTIONS.AMOCRM.PROCESSAMOREPORT });
    }
  };

  getSettingFunnelsTarget = async () => {
    const resFunnelsTarget = await Api.AmoCRM.getSettingFunnelsTarget();

    this.store.dispatch({
      type: ACTIONS.AMOCRM.TARGET_FUNNEL,
      payload: resFunnelsTarget,
    });
  };

  getSettingFunnelsDuplicate = async (targetPipelineId) => {
    const resFunnelsDuplicate = await Api.AmoCRM.getSettingFunnelsDuplicate(targetPipelineId);

    this.store.dispatch({
      type: ACTIONS.AMOCRM.DUPLICATE_FUNNEL,
      payload: resFunnelsDuplicate,
    });
  };

  getSaveSettingFunnels = async (rules) => {
    await Api.AmoCRM.getSaveSettingFunnels(rules);
  };

  setLoading = (value) => this.store.dispatch({
    type: ACTIONS.AMOCRM.LOADING_CONTACTS,
    payload: value,
  });

  getInfoContacts = async (accountId) => {
    this.setLoading(true);

    try {
      this.store.dispatch({
        type: ACTIONS.AMOCRM.PROCESSING_UPDATE_CONTACTS,
        payload: [],
      });

      const infoContacts = await Api.AmoCRM.getInfoContacts({ accountId });

      this.store.dispatch({
        type: ACTIONS.AMOCRM.UPDATE_CONTACTS,
        payload: infoContacts,
      });
    } catch (err) {
      this.store.dispatch({
        type: ACTIONS.AMOCRM.NO_UPDATE_CONTACTS,
        payload: [],
      });
    }

    this.setLoading(false);
  };

  downloadAmoContacts = async (guid) => {
    try {
      await Api.AmoCRM.downloadAmoContacts(guid);
    } catch (err) {
      this.store.dispatch({ type: ACTIONS.AMOCRM.NO_DOWNLOAD_CONTACTS });
    }
  };

  resetError = () => {
    this.store.dispatch({ type: ACTIONS.AMOCRM.RESETERROR });
  };

  resetStore = () => {
    this.store.dispatch({ type: ACTIONS.AMOCRM.RESET });
  };
}

export default AmoCRMService;

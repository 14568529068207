import ACTIONS from '../../actions';
import Api from '../../api';

import { findDepartments } from '../../utils/trip';
import { prepareApproveData, validateIntercomLink } from '../../utils/cart';

import { prepareDataForBookingRequest } from '../../utils/cartsAndNotes';

import {
  ERRORS, STEP_CONDITION_VALUES, VALIDATE_INTERCOM_LINK,
} from '../../constants/cart';

let store = null;

class CartsAndNotesService {
  constructor() {
    store = this.getStore('CartsAndNotes');
  }

  clearCartsAndNotes() {
    store.dispatch({ type: ACTIONS.CARTSANDNOTES.CLEARCARTSANDNOTES });
  }

  loadCartsAndNotes(userId, accountId, companyId) {
    store.dispatch({ type: ACTIONS.CARTSANDNOTES.LOADING });

    return Promise.all([
      Api.Cart.getCarts(userId),
      Api.Users.getNote(userId),
      Api.Users.getUser(userId),
      Api.Trip.getCompanies(accountId),
      Api.Trip.getProjects(accountId),
      Api.Trip.getUsers(companyId),
      Api.CustomAnalytics.getAnalyticsWithoutValues(accountId),
      Api.Trip.getMandatoryProject(accountId),
      Api.Cart.getUserInfo(userId),
    ]).then((resArr) => {
      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.LOADCARTSANDNOTES,
        carts: resArr[0],
        note: resArr[1],
        user: resArr[2],
        companies: resArr[3],
        projects: resArr[4],
        users: resArr[5],
        analytics: resArr[6],
        mandatoryProject: resArr[7],
        userInfo: resArr[8],
      });
    })
      .catch((err) => {
        console.error(err);
      });
  }

  changeCarts = (newCart) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.CHANGE_CARTS,
      props: newCart,
    });
  };

  setItemCart = (newItem) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.SET_ITEM_CART,
      props: newItem,
    });
  };

  createTripFromCart = async (companyId, data, email) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.LOADING,
      props: true,
    });

    try {
      const { tripId } = await Api.Trip.create(companyId, data, email);

      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.LOADING,
        props: false,
      });

      return tripId;
    } catch (err) {
      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.LOADING,
        props: false,
      });
    }

    return null;
  };

  getSchemeApprovers = async (cartId) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.LOADING_APPROVALS,
      props: true,
    });

    try {
      const result = await Api.Cart.getApprovals(cartId);

      const stepsByStepCondition = result.Steps.reduce((acc, {
        StepCondition, Approvers, Id,
      }) => {
        if (StepCondition === STEP_CONDITION_VALUES.ALL_APPROVERS) {
          const approvers = Approvers.map(({ UserId, Email }) => ({ UserId, Email }));

          return [...acc, { Id, Approvers: approvers }];
        }

        return acc;
      }, []);
      const resultSteps = result.Steps.map((item) => ({
        ...item,
        Approvers: item.Approvers.map((approver) => (
          {
            ...approver,
            LastName: approver.MiddleName,
            MiddleName: approver.LastName,
          }
        )),
      }));

      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.SET_APPROVERS,
        props: stepsByStepCondition,
      });

      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.APPROVALS_LIST,
        props: {
          ...result,
          Steps: resultSteps,
        },
      });

      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.SHOW_APPROVALS,
        props: true,
      });
    } catch (err) {
      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.LOADING_APPROVALS,
        props: false,
      });
    }
  };

  getApprovers = async () => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.LOADING_APPROVALS,
      props: true,
    });

    try {
      const result = await this.getApproversForUser();

      if (!result.length) {
        const resultAll = await this.getAllApproversForUser();

        store.dispatch({
          type: ACTIONS.CARTSANDNOTES.SHOW_APPROVALS,
          props: true,
        });

        return store.dispatch({
          type: ACTIONS.CARTSANDNOTES.APPROVALS_LIST,
          props: prepareApproveData(resultAll),
        });
      }

      store.dispatch({
        type: ACTIONS.CARTSANDNOTES.SHOW_APPROVALS,
        props: true,
      });

      return store.dispatch({
        type: ACTIONS.CARTSANDNOTES.APPROVALS_LIST,
        props: prepareApproveData(result),
      });
    } catch (err) {
      return store.dispatch({
        type: ACTIONS.CARTSANDNOTES.LOADING_APPROVALS,
        props: false,
      });
    }
  };

  getApproversForUser = async () => {
    const { employeeId } = store.getState();

    try {
      return await Api.Approval.getApproversForUser(employeeId);
    } catch (e) {
      return [];
    }
  };

  getApprovalName = async () => {
    const { employeeId } = store.getState();

    try {
      const { Name } = await Api.Approval.getApprovalScheme(employeeId);

      return store.dispatch({
        type: ACTIONS.CARTSANDNOTES.APPROVAL_NAME,
        props: Name,
      });
    } catch (e) {
      return null;
    }
  };

  getAllApproversForUser = async () => {
    const { employeeId } = store.getState();

    try {
      const approvers = await Api.Approval.getAllApproversForUser(employeeId);

      return approvers;
    } catch (e) {
      return [];
    }
  };

  getDepartments = async (id, companyId) => {
    try {
      const currentEmployee = await Api.Trip.getEmployeeDataByID(id);

      return findDepartments(currentEmployee, companyId);
    } catch (err) {
      return null;
    }
  };

  changeApprovers = (approver, stepId) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.CHANGE_APPROVERS,
      props: {
        approver,
        stepId,
      },
    });
  };

  changeComment = (comment) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.COMMENT_FOR_APPROVE,
      props: comment,
    });
  };

  sendToApprove = async (cartId, userId, accountId, companyId) => {
    const {
      selectedApprovers, commentForApprove, approvalsList,
    } = store.getState();

    const filteredSelectedApprovers = selectedApprovers.length
      ? selectedApprovers.every(({ Approvers }) => !!Approvers.length)
      : false;

    if (approvalsList.Steps.length !== selectedApprovers.length || !filteredSelectedApprovers) {
      return store.dispatch({
        type: ACTIONS.CARTSANDNOTES.ERROR_APPROVE,
        props: ERRORS.NO_SELECT_APPROVERS,
      });
    }

    const singleApprovers = approvalsList.IsSimple
      ? selectedApprovers[0].Approvers.map(({ Email }) => Email)
      : [];
    const multipleApprovers = !approvalsList.IsSimple
      ? selectedApprovers.map(({ Id, Approvers }) => ({
        Id,
        Approvers: Approvers.map(({ UserId }) => UserId),
      }))
      : [];

    const data = {
      CartId: cartId,
      Users: singleApprovers,
      Message: { Comment: commentForApprove },
      StepsSettings: multipleApprovers,
      Type: 'Default',
    };

    try {
      await Api.Cart.sendToApprove(data);

      this.loadCartsAndNotes(userId, accountId, companyId);

      return this.closeApprovers();
    } catch (err) {
      return null;
    }
  };

  closeApprovers = () => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.SHOW_APPROVALS,
      props: false,
    });
  };

  selectAnalytic = async (cartId, analyticsValueId) => {
    try {
      await Api.Cart.sendAnalytics(cartId, analyticsValueId);
    } catch (err) {
      return null;
    }
  };

  deleteAnalytic = async (cartId, analyticId) => {
    try {
      await Api.Cart.deleteAnalytic(cartId, analyticId);
    } catch (err) {
      return null;
    }
  };

  createCart = async (data) => {
    try {
      await Api.Cart.createCart(data);
    } catch (err) {
      return null;
    }
  };

  createItem = async (cartId, items) => {
    try {
      await Api.Cart.createItem(cartId, items);
    } catch (err) {
      return null;
    }
  };

  editItem = async (cartId, cartItemId, item) => {
    try {
      await Api.Cart.editItem(cartId, cartItemId, item);
    } catch (err) {
      return null;
    }
  };

  deleteCart = async (cartId) => {
    try {
      await Api.Cart.deleteCart(cartId);
    } catch (err) {
      return null;
    }
  };

  deleteItem = async (cartId, cartItemId) => {
    try {
      await Api.Cart.deleteItem(cartId, cartItemId);
    } catch (err) {
      return null;
    }
  };

  sendToSlack(payload) {
    return Api.Slack.send(payload);
  }

  get() {
    return store.getState();
  }

  set(newState) {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.SET,
      newState,
    });
  }

  setAnalytics = (analytics) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.SET_ANALYTICS,
      props: analytics,
    });
  };

  changeIntercomLink = (link, index) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.CHANGE_INTERCOM_LINK,
      props: {
        link,
        index,
      },
    });
  };

  saveIntercomLink = async (cartId, index, userId, accountId, companyId) => {
    const { link } = store.getState();

    if (validateIntercomLink(link[index])) {
      await Api.Cart.saveIntercomLink(cartId, JSON.stringify(link[index]));

      this.loadCartsAndNotes(userId, accountId, companyId);

      return store.dispatch({
        type: ACTIONS.CARTSANDNOTES.ERROR_INTERCOM_LINK,
        props: '',
      });
    }

    return store.dispatch({
      type: ACTIONS.CARTSANDNOTES.ERROR_INTERCOM_LINK,
      props: VALIDATE_INTERCOM_LINK,
    });
  };

  setIntercomLink = (link) => {
    store.dispatch({
      type: ACTIONS.CARTSANDNOTES.SAVE_INTERCOM_LINK,
      props: link,
    });
  };

  getRequestIdByCartId = (cartId) => Api.Cart.getBookingRequestIdByCartId(cartId);

  createBookingRequest = async (cart, accountId, userEmail) => {
    const data = prepareDataForBookingRequest(cart, accountId, userEmail);

    try {
      return await Api.Trip.saveHotelBookRequest(data);
    } catch (error) {
      return null;
    }
  };

  subscribe(callback) {
    return store.subscribe(callback);
  }
}

export default CartsAndNotesService;

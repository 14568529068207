import ACTIONS from '../../actions';
import Api from '../../api';
import CONFIG from '../../../config';

import { DATE } from '../../../constants/time';
import { formatDate } from '../../utils/formatDate';
import { alertDev } from '../../../utils/alert';

let store = null;

const { WORKPLACE, SMARTAGENT } = CONFIG;

class BusinessService {
  constructor() {
    store = this.getStore('Business');
  }

  subscribe(callback) {
    return store.subscribe(callback);
  }

  loadTripBi(date) {
    return Api.Business.loadTripReport(date.format(DATE)).then((res) => {
      store.dispatch({
        type: ACTIONS.BUSINESS.UPDATETRIP,
        payload: res,
      });
    });
  }

  loadSettings = () => Promise.allSettled([
    Api.Business.getAirlineAggregationList(),
    Api.Business.getAirlineAggregationDefault(),
    Api.Business.getAirlineAggregationProviders(),
    Api.Business.getHotelsAggregationProviders(),
  ])
    .then((res) => {
      store.dispatch({
        type: ACTIONS.BUSINESS.SHOW_SETTINGS,
        schemeSelectList: res[0],
        schemeSelectedValue: res[1],
        providersObject: res[2],
        hotelsProvidersObject: res[3],
      });
    });

  loadingAirlineAggregationProviders = (loading = true) => store.dispatch({
    type: ACTIONS.BUSINESS.LOADING_PROVIDERS,
    loading,
  });

  downloadTripBi(date) {
    return Api.Business.downloadTripReport(date.format(DATE));
  }

  autoDownloadSendingEdo(runTask) {
    return Api.Business.autoDownloadSendingEdo(runTask).then((res) => {
      store.dispatch({
        type: ACTIONS.BUSINESS.AUTO_SENDING_EDO,
        responseSendingProgress: res.Progress,
        responseSendingStatus: res.Status,
      });
    });
  }

  downloadPaymentCard(startDate, endDate) {
    return Api.Business.downloadPaymentCard(startDate, endDate);
  }

  monetaryTransactions(startDate, endDate) {
    return Api.Business.monetaryTransactions(startDate, endDate);
  }

  autoSendingEmailReport(startDate, endDate, email) {
    return Api.Business.downloadAutoSendingEmails(startDate, endDate, email);
  }

  generateLink = (client, email, password) => `${client}/login?username=${email}&password=${password}`;

  startWorkplaceClientSession = (email, time) => Api.Business.startClientSession(email, time)
    .then((password) => {
      const URL = this.generateLink(WORKPLACE, email, password);

      window.open(URL);
    });

  startSmartagentClientSession = (email, time) => Api.Business.startClientSession(email, time)
    .then((password) => {
      const URL = this.generateLink(SMARTAGENT, email, password);

      window.open(URL);
    });

  generateClientSessionPass(email, time) {
    return Api.Business.startClientSession(email, time)
      .then((password) => password)
      .catch(() => (''));
  }

  generalActCompletedWorks(companyId, startDate, endDate) {
    return Api.Business.downloadGeneralActCompletedWorks(companyId, startDate, endDate)
      .then(() => {
        store.dispatch({
          type: ACTIONS.BUSINESS.GENERAL_ACT_COMPLETED_WORKS,
          responseGeneralActStatus: false,
        });
      })
      .catch((e) => {
        alertDev(e, 'BusinessService', 'generalActCompletedWorks');
        store.dispatch({
          type: ACTIONS.BUSINESS.GENERAL_ACT_COMPLETED_WORKS,
          responseGeneralActStatus: true,
        });
      });
  }

  downloadDebtorBi() {
    return Api.Business.downloadDebtorReport();
  }

  downloadSendingTypes() {
    return Api.Business.downloadSendingTypes();
  }

  downloadEditingClosing(startDate, endDate) {
    return Api.Business.downloadEditingClosing(startDate.format(DATE), endDate.format(DATE));
  }

  downloadCompaniesDaysInDebt(startDate, endDate) {
    return Api.Business.downloadCompaniesDaysInDebt(startDate.format(DATE), endDate.format(DATE));
  }

  downloadAlvistReport(startDate, endDate) {
    return Api.Business.downloadAlvistReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadIncomingBi(startDate, endDate) {
    return Api.Business.downloadIncomingReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadBookingBi(startDate, endDate) {
    return Api.Business.downloadBookingReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadBookingBiWl(startDate, endDate) {
    return Api.Business.downloadBookingReportWl(formatDate(startDate, DATE), formatDate(endDate, DATE));
  }

  downloadBookingBiSpecialBilling(startDate, endDate) {
    return Api.Business.downloadBookingReportSpecialBilling(formatDate(startDate, DATE), formatDate(endDate, DATE));
  }

  downloadBookingBiSec(startDate, endDate) {
    return Api.Business.downloadBookingReportSec(startDate.format(DATE), endDate.format(DATE));
  }

  downloadBookingBiThird(startDate, endDate) {
    return Api.Business.downloadBookingReportThird(startDate.format(DATE), endDate.format(DATE));
  }

  downloadHotelsBi(startDate, endDate) {
    return Api.Business.downloadHotelsReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadHotelsPkBi(startDate, endDate) {
    return Api.Business.downloadHotelsReportPk(startDate.format(DATE), endDate.format(DATE));
  }

  downloadHotelsPaymentsPkBi(startDate, endDate) {
    return Api.Business.downloadHotelsPaymentsPk(startDate.format(DATE), endDate.format(DATE));
  }

  downloadTaxiBi(startDate, endDate) {
    return Api.Business.downloadTaxiReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadAirTicketsBi(startDate, endDate) {
    return Api.Business.downloadAirTicketsReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadPrintBi(startDate, endDate) {
    return Api.Business.downloadPrintReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadStyleBi(startDate, endDate) {
    return Api.Business.downloadStyleReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadTagsBi(startDate, endDate) {
    return Api.Business.downloadTagsReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadReplyBi(startDate, endDate) {
    return Api.Business.downloadReplyReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadKpipoBi(startDate, endDate) {
    return Api.Business.downloadKpipoReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadKpiNoteBi(startDate, endDate) {
    return Api.Business.downloadKpiNoteReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadTimeChatBi(startDate, endDate) {
    return Api.Business.downloadTimeChatReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadStopWordBi(startDate, endDate) {
    return Api.Business.downloadStopWordReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadConsultationsBi(startDate, endDate) {
    return Api.Business.downloadConsultationsReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadReport1Bi(startDate, endDate) {
    return Api.Business.downloadReport1(startDate.format(DATE), endDate.format(DATE));
  }

  downloadAccountsBi() {
    return Api.Business.downloadAccountsReport();
  }

  downloadTrainTicketsBi(startDate, endDate) {
    return Api.Business.downloadTrainTicketsReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadRefundsOneliyaBi(startDate, endDate) {
    return Api.Business.downloadRefundsOneliyaReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadProceedsBi(startDate, endDate) {
    return Api.Business.downloadProceedsReport(startDate.format(DATE), endDate.format(DATE));
  }

  getAirlineAggregationDefault() {
    return Api.Business.getAirlineAggregationDefault();
  }

  getAirlineAggregationList() {
    return Api.Business.getAirlineAggregationList();
  }

  setAirlineAggregationProviders(providersObject) {
    return Api.Business.setAirlineAggregationProviders(providersObject);
  }

  setHotelsAggregationProviders(providersObject) {
    return Api.Business.setHotelsAggregationProviders(providersObject);
  }

  setAirlineAggregation(scheme) {
    return Api.Business.setAirlineAggregation(scheme);
  }

  downloadManagersActionsBi(startDate, endDate) {
    return Api.Business.downloadManagersActions(startDate.format(DATE), endDate.format(DATE));
  }

  downloadOverdraftBi(startDate, endDate) {
    return Api.Business.downloadOverdraftReport(startDate.format(DATE), endDate.format(DATE));
  }

  downloadOverdraftBiSec(date) {
    return Api.Business.downloadOverdraftReportSec(formatDate(date, DATE));
  }

  downloadAnalyticsAmoBi(startDate, endDate) {
    return Api.Business.downloadAnalyticsAmoBi(startDate.format(DATE), endDate.format(DATE));
  }

  downloadHotelVat = (startDate, endDate) => (
    Api.Business.downloadHotelVat(startDate.format(DATE), endDate.format(DATE))
  );

  downloadAAVat = (startDate, endDate) => Api.Business.downloadAAVat(startDate.format(DATE), endDate.format(DATE));

  downloadSchemas = () => Api.Business.downloadSchemas();

  downloadReportInsuranceBi(startDate, endDate) {
    return Api.Business.downloadReportInsurance(formatDate(startDate, DATE), formatDate(endDate, DATE));
  }

  downloadReportInsuranceBiFiltered(startDate, endDate) {
    return Api.Business.downloadReportInsuranceFiltered(formatDate(startDate, DATE), formatDate(endDate, DATE));
  }

  downloadReportInsuranceBiConsolidated(startDate, endDate) {
    return Api.Business.downloadReportInsuranceConsolidated(formatDate(startDate, DATE), formatDate(endDate, DATE));
  }

  getSupplierDocuments = (startDate, endDate) => (
    Api.Business.getSupplierDocuments(formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then((res) => res.map((item) => {
        const { RequestDate, ReceiveDate } = item;

        return {
          ...item,
          RequestDate: Boolean(RequestDate),
          ReceiveDate: Boolean(ReceiveDate),
        };
      }))
      .catch(() => ([]))
  );

  sendSupplierDocumentsCheckbox = (id, field, value) => {
    const data = {
      TripItemId: id,
      DateType: field,
      Checkbox: value,
    };

    return Api.Business.sendSupplierDocumentsCheckbox(data);
  };

  getTripByDate = (startDate, endDate) => (
    Api.Business.getTripByDate(formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then((res) => res.trips)
      .catch(() => ([]))
  );

  getAboardByDate = async (startDate, endDate) => {
    try {
      return await Api.Business.getAboardByDate(formatDate(startDate, DATE), formatDate(endDate, DATE));
    } catch (err) {
      return err;
    }
  };

  getApartmentsByDate = (startDate, endDate) => (
    Api.Business.getApartmentsByDate(formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then((res) => res.trips)
      .catch(() => ([]))
  );

  getReconciliationWithInsurance = () => Api.Business.getReconciliationWithInsurance();

  sendApartmentsByDateCheckboxes = (tripId, checkboxes) => Api.Business.sendTripByDateCheckboxes(tripId, checkboxes);

  getPrintDocuments = (
    startDate, endDate, companyStart, companyEnd,
  ) => Api.Business.getPrintDocuments(formatDate(startDate, DATE), formatDate(endDate, DATE), companyStart, companyEnd)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.BUSINESS.PRINT_DOC_STATUS,
        responseProgress: res.Progress,
        responseStatus: res.Status,
      });
    });

  getAviaReport = (
    startDate, endDate, reportType,
  ) => Api.Business.getAviaReport(formatDate(startDate, DATE), formatDate(endDate, DATE), reportType);

  getCheckingStatusPrintDocuments = () => Api.Business.getCheckingStatusPrintDocuments().then((res) => {
    store.dispatch({
      type: ACTIONS.BUSINESS.PRINT_DOC_STATUS,
      responseProgress: res.Progress,
      responseStatus: res.Status,
    });
  });

  getAandaVsProviders = (startDate, endDate) => (
    Api.Business.getAandaVsProviders(formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then((res) => res.trips)
      .catch(() => ([]))
  );

  getWlRushydro = (startDate, endDate) => (
    Api.Business.getWlRushydro(formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then((res) => res.trips)
      .catch(() => ([]))
  );

  getAandaReport = (startDate, endDate) => (
    Api.Business.getAandaReport(formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then((res) => res.trips)
      .catch(() => ([]))
  );

  getMiceReport = (startDate, endDate) => (
    Api.Business.getMiceReport(formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then((res) => res.trips)
      .catch(() => ([]))
  );

  sendTripByDateCheckboxes = (tripId, checkboxes) => Api.Business.sendTripByDateCheckboxes(tripId, checkboxes);

  sendAboardDateCheckboxrs = (tripItemId, checkboxes) => Api.Business.sendAboardDateCheckboxrs(tripItemId, checkboxes);

  toggleAboardCheckbox = async (id, checkboxes, list) => {
    try {
      await this.sendAboardDateCheckboxrs(id, checkboxes);

      const newList = list.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          ...checkboxes,
        };
      });

      return newList;
    } catch (err) {
      return false;
    }
  };

  get = () => store.getState();
}

export default BusinessService;

import { Response } from 'superagent';

import ApiInstance from '../../api';

import { bookingStore } from '../../stores/booking';

import { BookingSettingsModel } from '../../../modules/settings/components/BookingSettings';
import { DefaultSettings } from '../../../modules/settings/components/BookingSettings/lib';

import { VoidFnType } from './types';

export default class BookingService {
  public bookingStore = bookingStore;

  sendBookingSettings = async (
    successFn: VoidFnType,
    failureFn?: VoidFnType,
  ) => {
    this.bookingStore.setLoading(true);

    try {
      await ApiInstance.Booking.setBookingSettings(bookingStore.bookingSettings);
      successFn();
    } catch (err: unknown) {
      if (err instanceof Response) {
        (failureFn ? failureFn : () => {})();
      }
    } finally {
      this.bookingStore.setLoading(false);
    }
  };

  resetBookingSettings = async (
    successFn: VoidFnType,
    failureFn?: VoidFnType,
  ) => {
    this.bookingStore.setLoading(true);

    try {
      await ApiInstance.Booking.resetBookingSettings();
      this.bookingStore.setBookingSettings(DefaultSettings);
      successFn();
    } catch (err: unknown) {
      if (err instanceof Response) {
        (failureFn ? failureFn : () => {})();
      }
    } finally {
      this.bookingStore.setLoading(false);
    }
  };

  setBookingSettings = (data: BookingSettingsModel) => bookingStore.setBookingSettings(data);

  loadBookingSettings = async () => {
    const data = await ApiInstance.Booking.getBookingSettings();
    this.bookingStore.setBookingSettings(data);
  };
}
